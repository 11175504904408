import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

import SEO from 'components/seo'
import Navbar from 'components/navbar'
import IndexLayout from 'components/IndexLayout'
import Button from 'components/button'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10rem;
`

const Title = styled.div`
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
  text-align: center;

  ${mq['md']} {
    font-size: 2rem;
  }

  ${mq['sm']} {
    font-size: 1.8rem;
  }
`

const Whoops = () => {
  const { t } = useTranslation('whoops')
  const HOME_PATH = '/'

  const [isNavbarOpen, setNavbarOpen] = useState(false)

  const onClickNavigateHome = () => {
    navigate(HOME_PATH)
  }

  return (
    <IndexLayout>
      <SEO title={t('title')} />
      <Navbar isNavbarOpen={isNavbarOpen} setNavbarOpen={setNavbarOpen} />
      <ContentContainer>
        <Title>{t('heading')}</Title>
        <Button onClick={() => onClickNavigateHome()}>{t('buttonText')}</Button>
      </ContentContainer>
    </IndexLayout>
  )
}

export default Whoops
